import { useAppStore } from '@/store/app';

export type LeaguesApiDomain = 'profiles' | 'cloud' | 'timelines' | 'hubs' | 'supersub' | 'streaming';

export function getLeaguesApiBaseUrl(domain: LeaguesApiDomain) {
  const appStore = useAppStore();

  switch (domain) {
    case 'profiles':
      return `https://profiles-${appStore.environment}.leagues.network`;
    case 'cloud':
      return `https://cloud-${appStore.environment}.leagues.football`;
    case 'timelines':
      // return `http://localhost:5000`;
      return `https://timelines-${appStore.environment}.leagues.network`;
    case 'hubs':
      return `wss://supersub-${appStore.environment}.leagues.network`;
    case 'supersub':
      return `https://supersub-${appStore.environment}.leagues.network`;
    case 'streaming':
      return 'https://api-dev.ott.leagues.network';
    default:
      throw new Error(`Unknown API domain: ${domain}`);
  }
}

export function buildLeaguesApiUrl(domain: LeaguesApiDomain, path: string) {
  const baseUrl = getLeaguesApiBaseUrl(domain);

  let finalUrl: string;

  // Prüfen, ob die URL tatsächlich eine vollständige URL ist oder nur ein Pfad
  if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('wss://')) {
    // Erstellt ein URL-Objekt aus dem übergebenen String
    const urlObj = new URL(path);
    // Holt nur den Pfad und die Abfrage der URL
    finalUrl = urlObj.pathname + urlObj.search;
  } else {
    // Wenn es nur ein Pfad ist, verwenden wir ihn direkt
    finalUrl = path;
  }

  // Stellt sicher, dass 'finalUrl' nicht mit einem Slash beginnt, um doppelte Schrägstriche zu vermeiden
  finalUrl = finalUrl.startsWith('/') ? finalUrl.slice(1) : finalUrl;

  return `${baseUrl}/${finalUrl}`;
}

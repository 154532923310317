// noinspection JSUnusedGlobalSymbols

import axios, { type AxiosError, AxiosRequestConfig } from 'axios';
import { useAuthStore } from '@/store/auth';
import { buildLeaguesApiUrl, type LeaguesApiDomain } from './leagues-api/leagues-api-urls';

export type ApiResponseV2<T> = {
  data: T;
  status: number;
};

export async function httpPatch<T = any>(
  url: string,
  api: LeaguesApiDomain,
  data: any,
  { contentType = 'application/json', abortSignal }: { contentType?: string; abortSignal?: AbortSignal } = {},
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  const response = await axios.patch(apiUrl, data, {
    headers: { ...buildHeaders(contentType) },
    signal: abortSignal,
  });
  return { data: response.data, status: response.status };
}

export async function httpGet<T = any>(
  url: string,
  api: LeaguesApiDomain,
  {
    contentType = 'application/x-www-form-urlencoded',
    abortSignal,
  }: { contentType?: string; abortSignal?: AbortSignal } = {},
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  const response = await axios.get(apiUrl, { signal: abortSignal, headers: buildHeaders(contentType) });
  return { data: response.data, status: response.status };
}

export async function httpPost<T = any>(
  url: string,
  api: LeaguesApiDomain,
  data: object,
  {
    contentType = 'application/json',
    onProgress,
    abortSignal,
  }: {
    contentType?: string;
    onProgress?: AxiosRequestConfig['onUploadProgress'];
    abortSignal?: AxiosRequestConfig['signal'];
  } = {},
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  try {
    const response = await axios.post(apiUrl, data, {
      onUploadProgress: onProgress,
      signal: abortSignal,
      headers: buildHeaders(contentType),
    });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ title: string; detail: string }>;

      // Prüfen, ob der Fehler ein Response-Objekt enthält
      if (axiosError.response) {
        const serverResponse = axiosError.response.data;
        if (serverResponse) {
          console.error('Conflict detected: ', serverResponse);
          throw error;
        }
      }
    }
    throw error;
  }
}

export async function httpPut<T = any>(
  url: string,
  api: LeaguesApiDomain,
  data: object,
  contentType = 'application/json',
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);
  const response = await axios.put(apiUrl, data, { headers: buildHeaders(contentType) });
  return { data: response.data, status: response.status };
}

export async function httpUploadFile<T = any>(
  url: string,
  api: LeaguesApiDomain,
  file: File,
  contentType = '',
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': contentType || file.type, ...buildHeaders(contentType || file.type) },
    body: file, // Direktes Senden des Blob oder File Objekts
  });
  const data = await response.json();
  return { data: data as T, status: response.status };
}

export async function httpPostFile<T = any>(
  url: string,
  api: LeaguesApiDomain,
  file: File,
  contentType = '',
  {
    onProgress,
    abortSignal,
  }: { onProgress?: AxiosRequestConfig['onUploadProgress']; abortSignal?: AxiosRequestConfig['signal'] } = {},
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, wenn wir axios mit FormData verwenden.
  const formData = new FormData();
  formData.append('file', file, file.name);

  const response = await axios.post(apiUrl, formData, {
    onUploadProgress: onProgress,
    signal: abortSignal,
    headers: buildHeaders(contentType || file.type),
  });
  return { data: response.data, status: response.status };
}

export async function httpPostMultipartFile<T = any>(
  url: string,
  api: LeaguesApiDomain,
  blob: Blob,
  filename: string,
  {
    onProgress,
    abortSignal,
  }: { onProgress?: AxiosRequestConfig['onUploadProgress']; abortSignal?: AxiosRequestConfig['signal'] } = {},
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, wenn wir axios mit FormData verwenden.
  const formData = new FormData();
  formData.append('file', blob, filename);

  const response = await axios.post(apiUrl, formData, {
    onUploadProgress: onProgress,
    signal: abortSignal,
    headers: buildHeaders('multipart/form-data', filename),
  });
  return { data: response.data, status: response.status };
}

export async function httpPutFile<T = any>(
  url: string,
  api: LeaguesApiDomain,
  file: File,
  contentType = '',
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, wenn wir axios mit FormData verwenden.
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.put(apiUrl, formData, { headers: buildHeaders(contentType) });
  return { data: response.data, status: response.status };
}

export async function httpDelete<T = any>(
  url: string,
  api: LeaguesApiDomain,
  data: object = {},
  contentType = 'application/json',
): Promise<ApiResponseV2<T>> {
  const apiUrl = getApiUrl(api, url);

  const response = await axios.delete(apiUrl, { data: data, headers: buildHeaders(contentType) });
  return { data: response.data, status: response.status };
}

//#region Internals

function buildHeaders(contentType?: string, filename?: string) {
  const authStore = useAuthStore();
  return {
    Authorization: `Bearer ${authStore.accessToken}`,
    ...(contentType ? { 'Content-Type': contentType } : {}),
    ...(filename ? { 'Content-Disposition': `attachment; name="${filename}"; filename="${filename}"` } : {}),
  } as const;
}

function getApiUrl(domain: LeaguesApiDomain, url: string) {
  return buildLeaguesApiUrl(domain, url);
}

//#endregion
